import React, {
  MouseEvent,
  PropsWithChildren,
  ReactChild,
  ReactElement,
  useState,
} from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import {
  DefaultComponentProps,
  OverridableTypeMap,
  OverrideProps,
} from '@mui/material/OverridableComponent';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { Breakpoint, SxProps, Theme } from '@mui/material/styles';

export type AllowedComponents = 'a' | 'div' | 'p' | 'span' | typeof Link;

export interface OverflowTextTypeMap<
  // eslint-disable-next-line @typescript-eslint/ban-types
  P = {},
  D extends AllowedComponents = 'span',
> {
  props: P & {
    maxLines?: number | Partial<Record<Breakpoint, number>>;
    tooltip?: ReactChild;
    TooltipProps?: Omit<
      TooltipProps,
      | 'title'
      | 'disableHoverListener'
      | 'disableFocusListener'
      | 'disableTouchListener'
      | 'children'
    >;
  } & Omit<TypographyProps<D>, 'component'>;
  defaultComponent: D;
}

export type OverflowTextProps<
  D extends AllowedComponents = OverflowTextTypeMap['defaultComponent'],
  // eslint-disable-next-line @typescript-eslint/ban-types
  P = {},
> = OverrideProps<OverflowTextTypeMap<P, D>, D>;

export interface OverflowTextComponent<M extends OverridableTypeMap> {
  <C extends AllowedComponents>(
    props: { component?: C } & OverrideProps<M, C>,
  ): ReactElement;
  (props: DefaultComponentProps<M>): ReactElement;
}

const OverflowChildren: SxProps<Theme> = {
  overflow: 'hidden',
  overflowWrap: 'anywhere',
  textOverflow: 'ellipsis',
  whiteSpace: 'initial',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
};

export const OverflowText: OverflowTextComponent<OverflowTextTypeMap> = ({
  maxLines = 1,
  tooltip,
  children,
  TooltipProps,
  ...typographyProps
}: PropsWithChildren<OverflowTextProps>): ReactElement => {
  const [isOverflowing, setOverflowing] = useState(false);
  // When applying ellipsis to a single line we want to show as much of the text as possible ('break-all'),
  // however, when overflowing on multiple lines we don't want to break in the middle of words ('normal')
  const wordBreak = maxLines === 1 ? 'break-all' : 'normal';

  const updateOverflow = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target instanceof Element) {
      setOverflowing(e.target.scrollHeight > e.target.clientHeight);
    }
  };

  return (
    <Tooltip
      {...TooltipProps}
      title={tooltip || children || ''}
      disableHoverListener={!isOverflowing}
      disableFocusListener={!isOverflowing}
      disableTouchListener={!isOverflowing}
    >
      <Box
        onMouseEnter={updateOverflow}
        sx={{
          overflow: 'hidden',
          '> *': { ...OverflowChildren, wordBreak, WebkitLineClamp: maxLines },
        }}
      >
        <Typography {...typographyProps}>{children}</Typography>
      </Box>
    </Tooltip>
  );
};
