export const shuttlerockBlue = Object.freeze({
  50: '#E3F2FE',
  100: '#BBDFFE',
  200: '#8FCBFE',
  300: '#62B7FD',
  400: '#47A1FF',
  500: '#2F90F5',
  600: '#168AEE',
  700: '#1476DB',
  800: '#2064AB',
  900: '#0E48AA',
});

export const green = Object.freeze({
  50: '#dffae9',
  100: '#ccf7db',
  200: '#99efb7',
  300: '#65e793',
  400: '#32df6f',
  500: '#1db954',
  600: '#179443',
  700: '#116f32',
  800: '#0c4a22',
  900: '#062511',
});

export const red = Object.freeze({
  50: '#FEF2F2',
  100: '#FEE2E2',
  200: '#FECACA',
  300: '#FCA5A5',
  400: '#F87171',
  500: '#E91D2E',
  600: '#DC2626',
  700: '#B91C1C',
  800: '#991B1B',
  900: '#7F1D1D',
});

export const amber = Object.freeze({
  50: '#fffbeb',
  100: '#fef3c7',
  200: '#fde68a',
  300: '#fcd34d',
  400: '#fbbf24',
  500: '#f59e0b',
  600: '#ea8109',
  700: '#d85d00',
  800: '#b84702',
  900: '#9a3515',
});

export const purple = Object.freeze({
  50: '#F5F3FF',
  100: '#EDE9FE',
  200: '#DDD6FE',
  300: '#C4B5FD',
  400: '#A78BFA',
  500: '#8B5CF6',
  600: '#7C3AED',
  700: '#6D28D9',
  800: '#5B21B6',
  900: '#4C1D95',
});

export const grey = Object.freeze({
  50: '#FCFCFC',
  100: '#F4F4F4',
  200: '#EEEEEE',
  300: '#E0E0E0',
  400: '#BDBDBD',
  500: '#9E9E9E',
  600: '#737373',
  700: '#616161',
  800: '#424242',
  900: '#212121',
  A50: '#FFFFFF',
  A100: 'rgba(17, 17, 17, .05)',
  A200: 'rgba(17, 17, 17, .15)',
  A300: 'rgba(17, 17, 17, .3)',
  A400: 'rgba(17, 17, 17, .4)',
  A500: 'rgba(17, 17, 17, .5)',
  A600: 'rgba(17, 17, 17, .6)',
  A700: 'rgba(17, 17, 17, .7)',
  A800: 'rgba(17, 17, 17, .8)',
  A900: 'rgba(17, 17, 17, 1)',
});

export const gradient = Object.freeze({
  main: 'linear-gradient(89.99deg, #6D28D9 7.91%, #2064AB 99.99%);',
  hover: 'linear-gradient(89.99deg, #8B5CF6 7.91%, #2064AB 99.89%);',
  background:
    'linear-gradient(155.75deg, #17F2F2 8.22%, #2758D7 57.63%, #B424E8 103.09%)',
  blueToPurple:
    'linear-gradient(100.23deg, #4FA6F8 -0.53%, #9F48F1 70.81%, #E966DC 115.3%)',
  purpleToBlue: 'linear-gradient(133deg, #8A4BBC 21.36%, #3E55B9 83.84%)',
  pinkToOrange:
    'linear-gradient(121deg, #ED70AC -6.44%, #ED6F5D 54.87%, #F6C554 108.68%)',
});
