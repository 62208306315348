import React from 'react';
import { alpha, tableRowClasses } from '@mui/material';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { makeTheme } from './baseTheme';
import {
  gradient,
  grey,
  green,
  amber,
  red,
  shuttlerockBlue,
  purple,
} from './lightColors';
import { FaIcon } from '../components/DataDisplay/Icon/FaIcon';

/**
 * `LightTheme` defines the colour palette used in light mode, along with component overrides that are specific
 * to light mode
 *
 * Typically, this includes styles that influence colours
 */
export const LightTheme = makeTheme({
  palette: {
    mode: 'light',
    primary: {
      background: shuttlerockBlue[100],
      light: shuttlerockBlue[500],
      main: shuttlerockBlue[700],
      dark: shuttlerockBlue[800],
      hover: shuttlerockBlue[800],
      active: shuttlerockBlue[800],
      contrastText: grey.A50,
    },
    secondary: {
      light: grey.A100,
      main: grey.A500,
      dark: grey.A800,
      contrastText: grey.A50,
    },
    error: {
      background: red[100],
      light: red[500],
      main: red[700],
      dark: red[800],
      contrastText: grey.A50,
    },
    info: {
      background: shuttlerockBlue[100],
      light: shuttlerockBlue[50],
      main: shuttlerockBlue[700],
      dark: shuttlerockBlue[800],
      contrastText: grey.A50,
    },
    success: {
      background: green[100],
      light: green[100],
      main: green[500],
      active: green[600],
      dark: green[700],
    },
    warning: {
      background: amber[50],
      light: amber[500],
      main: amber[700],
      dark: amber[800],
      contrastText: grey.A50,
    },
    background: {
      default: grey[100],
      paper: grey[50],
      secondary: grey[200],
      card: grey[100],
      cardHover: grey[200],
      cardAlt: grey[50],
      cardAltHover: grey.A50,
    },
    foreground: {
      main: grey[50],
      secondary: grey[100],
      tertiary: grey[200],
      hover: grey[300],
    },
    stroke: {
      main: grey[200],
      hover: grey[500],
      active: shuttlerockBlue[700],
      dark: grey[300],
    },
    text: {
      primary: grey.A900,
      primaryAlt: grey.A800,
      secondary: grey[600],
      disabled: grey[400],
    },
    divider: grey[300],
    gradient: {
      main: gradient.main,
      light: gradient.hover,
      background: gradient.background,
      blueToPurple: gradient.blueToPurple,
      purpleToBlue: gradient.purpleToBlue,
      pinkToOrange: gradient.pinkToOrange,
      contrastText: grey.A50,
    },
    automation: {
      background: purple[100],
      light: purple[50],
      main: purple[500],
      dark: purple[700],
    },
    instructions: {
      light: grey[50],
      main: purple[500],
      dark: grey[800],
    },
    action: {
      disabled: grey[400],
      disabledBackground: grey[100],
    },
    gender: {
      all: green[500],
      female: purple[500],
      male: shuttlerockBlue[500],
    },
    grey,
  },
  components: {
    MuiAlert: {
      defaultProps: {
        variant: 'filled',
        iconMapping: {
          info: <FaIcon icon={faCircleInfo} />,
        },
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState?.variant === 'standard' &&
            ownerState?.color === 'secondary' && {
              '&.MuiAlert-standardSecondary': {
                backgroundColor: grey[100],
                color: grey[900],
                '& .MuiAlertTitle-root': {
                  color: grey[700],
                },
                '& .MuiAlert-icon': {
                  color: grey[700],
                  opacity: 'initial',
                },
              },
            }),
          ...(ownerState?.variant === 'standard' &&
            ownerState?.color === 'automation' && {
              '&.MuiAlert-standardAutomation': {
                backgroundColor: purple[100],
                color: purple[900],
                '& .MuiAlertTitle-root': {
                  color: purple[700],
                },
                '& .MuiAlert-icon': {
                  color: purple[700],
                  opacity: 'initial',
                },
              },
            }),
          ...(ownerState?.variant === 'standard' &&
            ownerState?.severity === 'error' && {
              backgroundColor: red[50],
              color: red[600],
              '& .MuiAlert-icon': {
                color: red[500],
                opacity: 'initial',
              },
            }),
          ...(ownerState?.variant === 'standard' &&
            ownerState?.severity === 'info' && {
              backgroundColor: shuttlerockBlue[50],
              color: shuttlerockBlue[800],
              '& .MuiAlert-icon': {
                color: shuttlerockBlue[700],
                opacity: 'initial',
              },
            }),
          ...(ownerState?.variant === 'standard' &&
            ownerState?.severity === 'warning' && {
              backgroundColor: amber[50],
              color: amber[800],
              '& .MuiAlert-icon': {
                color: amber[500],
                opacity: 'initial',
              },
            }),
          ...(ownerState?.variant === 'standard' &&
            ownerState?.color === 'instructions' && {
              color: grey[800],
              // applying the opacity on the background all while using a hacky css to apply gradient on boarder reduced our options greatly, hence the use of hsl.
              background: `linear-gradient(100.23deg, hsl(209, 100%, 95%) -0.53%, hsl(271, 100%, 95%) 70.81%, hsl(306, 100%, 95%) 115.3%) padding-box, ${gradient.blueToPurple} border-box`,
              border: '2px solid transparent',
              '& .MuiAlert-icon': {
                color: grey[800],
                opacity: 'initial',
              },
              '& .MuiAlert-message': {
                color: grey[800],
                opacity: 'initial',
              },
              '& .MuiAlert-action': {
                color: grey[800],
                opacity: 'initial',
              },
            }),
          ...(ownerState?.variant === 'outlined' &&
            ownerState?.severity === 'info' && {
              backgroundColor: shuttlerockBlue[50],
              color: shuttlerockBlue[700],
              borderColor: shuttlerockBlue[500],
              '& .MuiAlert-icon': {
                color: shuttlerockBlue[700],
                opacity: 'initial',
              },
            }),
          ...(ownerState?.variant === 'outlined' &&
            ownerState?.color === 'instructions' && {
              backgroundColor: grey[50],
              color: grey[800],
              background: `linear-gradient(white, white) padding-box, ${gradient.blueToPurple} border-box`,
              border: '2px solid transparent',
              '& .MuiAlert-icon': {
                color: shuttlerockBlue[700],
                opacity: 'initial',
              },
              '& .MuiAlert-action': {
                color: purple[700],
                opacity: 'initial',
              },
            }),
          ...(ownerState?.variant === 'outlined' &&
            ownerState?.severity === 'warning' && {
              backgroundColor: amber[50],
              color: amber[700],
              borderColor: amber[700],
              '& .MuiAlert-icon': {
                color: amber[700],
                opacity: 'initial',
              },
            }),
          ...(ownerState?.variant === 'standard' &&
            ownerState?.severity === 'success' && {
              backgroundColor: green[50],
              color: green[600],
              '& .MuiAlert-icon': {
                color: green[500],
                opacity: 'initial',
              },
            }),
          ...(ownerState?.variant === 'outlined' &&
            ownerState?.color === 'secondary' && {
              '&.MuiAlert-outlinedSecondary': {
                borderColor: grey[500],
                color: grey[800],
                '& .MuiAlert-icon': {
                  color: grey[500],
                  opacity: 'initial',
                },
              },
            }),
          ...(ownerState?.variant === 'outlined' &&
            ownerState?.color === 'automation' && {
              '&.MuiAlert-outlinedAutomation': {
                borderColor: purple[500],
                color: purple[800],
                '& .MuiAlert-icon': {
                  color: purple[500],
                  opacity: 'initial',
                },
              },
            }),
          ...(ownerState?.variant === 'filled' &&
            ownerState?.color === 'instructions' && {
              color: grey[50],
              background: gradient.blueToPurple,
            }),
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&:first-letter': {
            textTransform: 'capitalize',
          },
        },
      },
      variants: [
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            backgroundColor: grey[100],
            color: shuttlerockBlue[700],
            '&:hover': {
              backgroundColor: grey[200],
            },
          },
        },
        {
          props: { variant: 'contained', color: 'automation' },
          style: {
            backgroundColor: purple[600],
            color: grey.A50,
            '&:hover': {
              backgroundColor: purple[700],
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            backgroundColor: 'white',
            borderColor: grey[400],
            color: shuttlerockBlue[700],
            '&:hover': {
              backgroundColor: grey[100],
              borderColor: grey[400],
              color: shuttlerockBlue[700],
            },
            '&[disabled]': {
              borderColor: grey[400],
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            borderColor: shuttlerockBlue[700],
            '&:hover': {
              color: 'white',
              backgroundColor: shuttlerockBlue[800],
            },
            '&:active': {
              color: 'white',
              backgroundColor: shuttlerockBlue[800],
            },
            '&[disabled]': {
              borderColor: grey[400],
            },
          },
        },
        // Note for those who came here looking why the button is red.
        // That is left intentionally.
        // Secondary button should not be used in `text` variant!!!
        {
          props: { variant: 'contained', color: 'inherit' },
          style: {
            backgroundColor: grey[100],
            color: grey.A800,
            '&:hover': {
              backgroundColor: grey[200],
            },
          },
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: grey.A50,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          color: grey[300],
          '&.Mui-checked': {
            ...(ownerState?.readOnly && { color: grey[600] }),
            '& + .MuiFormControlLabel-label': {
              color: grey.A900,
            },
          },
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        filled: ({ ownerState, theme }) => ({
          ...(ownerState?.color === 'success' && {
            color: theme.palette.success.main,
            backgroundColor: green[100],
          }),
          ...(ownerState?.color === 'warning' && {
            color: theme.palette.warning.main,
            backgroundColor: amber[100],
          }),
          ...(ownerState?.color === 'error' && {
            color: theme.palette.error.main,
            backgroundColor: red[100],
          }),
          // Because secondary was used with error colours, ive duplicated them here (default already has the grey colours if need be)
          ...(ownerState?.color === 'secondary' && {
            color: theme.palette.error.main,
            backgroundColor: red[100],
          }),
          ...(ownerState?.color === 'default' &&
            ownerState?.size !== 'xsmall' && {
              color: theme.palette.grey[800],
              backgroundColor: theme.palette.grey[100],
            }),
          ...(ownerState?.color === 'default' &&
            ownerState?.size === 'xsmall' && {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.grey.A200,
            }),
        }),
        root: ({ ownerState, theme }) => ({
          ...(ownerState?.variant === 'm3' && {
            ...(ownerState?.color === 'success' && {
              color: theme.palette.success.main,
              '&, &:hover': {
                backgroundColor: green[100],
              },
            }),
            ...(ownerState?.color === 'warning' && {
              color: theme.palette.warning.main,
              '&, &:hover': {
                backgroundColor: amber[100],
              },
            }),
            ...(ownerState?.color === 'error' && {
              color: theme.palette.error.main,
              '&, &:hover': {
                backgroundColor: red[100],
              },
            }),
            ...(ownerState?.color === 'secondary' && {
              color: theme.palette.grey.A50,
              '&, &:hover': {
                backgroundColor: theme.palette.grey[800],
              },
            }),
            ...(ownerState?.color === 'default' && {
              color: theme.palette.grey[50],
              '&, &:hover': {
                backgroundColor: theme.palette.grey[300],
              },
            }),
            ...((ownerState?.color === 'primary' ||
              ownerState?.color === 'info') && {
              '&, &:hover': {
                backgroundColor: theme.palette.primary.main,
              },
            }),
          }),
        }),
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '::selection': {
          backgroundColor: alpha(shuttlerockBlue[500], 0.3),
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: grey[900],
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: grey.A800,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: grey.A900,
          '&.Mui-focused:not(.Mui-error)': {
            color: grey.A900,
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        barColorPrimary: { backgroundColor: shuttlerockBlue[500] },
        colorPrimary: { backgroundColor: grey[200] },
        root: {
          '& .MuiLinearProgress-barColorSuccess': {
            backgroundColor: green[500],
          },
          '& .MuiLinearProgress-barColorError': {
            backgroundColor: red[500],
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            backgroundColor: grey.A50,
            color: grey.A800,
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: grey[100],
          '&:hover': {
            backgroundColor: grey[200],
          },
        },
        input: {
          color: grey[800],
          '&::placeholder': {
            color: grey[500],
            opacity: 1,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ ownerState }) => {
          const color = grey.A800;
          const readOnly = ownerState?.readOnly;

          return readOnly
            ? {
                color,
                backgroundColor: 'transparent',
                boxShadow: 'none',
                fieldset: { border: 'none' },
              }
            : {
                color,
                backgroundColor: grey.A50,
                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.024)',
                fieldset: { borderColor: grey[300] },
                '&.Mui-disabled': {
                  backgroundColor: grey[100],
                  fieldset: {
                    borderColor: `${grey[100]} !important`, // :'(
                  },
                },
                '&:hover:not(:focus-within):not(.Mui-disabled):not(.Mui-error) fieldset':
                  {
                    borderColor: grey[500],
                  },
                '&:focus-within:not(.Mui-error) fieldset': {
                  borderColor: shuttlerockBlue[500],
                  borderWidth: '1px',
                },
              };
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          color: grey[300],
          '&.Mui-checked': {
            ...(ownerState?.readOnly && { color: grey[600] }),
            '& + .MuiFormControlLabel-label': {
              color: grey.A900,
            },
          },
        }),
      },
    },
    MuiSlider: {
      styleOverrides: {
        colorPrimary: {
          color: shuttlerockBlue[400],
        },
        rail: {
          color: grey.A200,
        },
        mark: {
          backgroundColor: grey.A200,
          height: 3,
          width: 3,
        },
        root: {
          '.MuiSlider-markActive': {
            backgroundColor: grey.A600,
          },
        },
        track: {
          border: 'none',
        },
      },
      variants: [
        {
          props: { size: 'medium' },
          style: {
            height: '6px',
          },
        },
      ],
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorPrimary: { color: shuttlerockBlue[500] },
        root: {
          '&.MuiSvgIcon-colorSuccess': {
            color: green[500],
          },
          '&.MuiSvgIcon-colorError': {
            color: red[500],
          },
          '&.MuiSvgIcon-colorAutomation': {
            color: purple[700],
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: grey[100],
          },
          '&.Mui-selected': {
            backgroundColor: grey.A50,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-flexContainer:after': {
            backgroundColor: grey[200],
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          [`&.${tableRowClasses.hover}:hover`]: {
            backgroundColor: grey.A100,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: grey.A100,
        },
        body: {
          color: grey.A800,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'white',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: grey[800],
        },
        tooltip: {
          backgroundColor: grey[800],
          boxShadow: '0px 4px 44px rgba(0, 0, 0, 0.25)',
          color: grey[50],
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState?.variant === 'overline' && {
            color: purple[700],
          }),
          ...(ownerState?.color === 'gradient' && {
            background: gradient.main,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }),
          ...(ownerState?.color === 'gradient.blueToPurple' && {
            background: gradient.blueToPurple,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }),
        }),
      },
    },
  },
});
