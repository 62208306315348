import { forwardRef, PropsWithChildren, ReactElement, ReactNode } from 'react';
import { FormControlLabel, Checkbox, FormGroup } from '@mui/material';
import { OverflowText } from '../../DataDisplay/OverflowText/OverflowText';

export const SrFilter = ({ children }: PropsWithChildren): ReactElement => {
  return <FormGroup>{children}</FormGroup>;
};

type SrFilterCheckboxProps = {
  id?: string;
  name?: string;
  label?: ReactNode;
  value: string;
  defaultChecked?: boolean;
  checked?: boolean;
  onCheckedChange?(isChecked: boolean): void;
};

const FilterCheckbox = forwardRef<HTMLInputElement, SrFilterCheckboxProps>(
  (
    { id, name, label, value, defaultChecked, checked, onCheckedChange },
    ref,
  ) => {
    return (
      <FormControlLabel
        disableTypography
        label={
          <OverflowText variant="inputLabel" sx={{ color: 'text.primaryAlt' }}>
            {label ?? value}
          </OverflowText>
        }
        control={
          <Checkbox
            inputRef={ref}
            id={id}
            name={name}
            value={value}
            defaultChecked={defaultChecked}
            checked={checked}
            onChange={(e) => onCheckedChange?.(e.target.checked)}
            sx={{
              py: 0.75,
              color: 'grey.A700',
              '&.Mui-checked svg': {
                color: 'primary.light',
              },
            }}
          />
        }
      />
    );
  },
);

SrFilter.Checkbox = FilterCheckbox;
